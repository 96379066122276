import FiveStarRating from './questions/FiveStarRating';
import ShortTextResponse from './questions/ShortTextResponse';
import LongTextResponse from './questions/LongTextResponse';
import MultipleChoice from './questions/MultipleChoice';
import PictureResponse from './questions/PictureResponse.jsx';
import Timer from './questions/Timer';
import QuestionCard from './questions/QuestionCard';
import axios from '../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { Question } from '../../models/Question.js';
import Spinner from '../common/spinner/spinner.jsx';
import {
	Stack,
	TextField,
	Card,
	CardContent,
	SpeedDial,
	SpeedDialAction,
	SpeedDialIcon,
	Box,
	Button,
	Typography,
	IconButton,
} from '@mui/material';
import SlidingScale from './questions/SlidingScale.jsx';
import YesNo from './questions/YesNo.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import TimerIcon from '@mui/icons-material/Timer';
import StarIcon from '@mui/icons-material/Star';
import SubjectIcon from '@mui/icons-material/Subject';
import ShortTextIcon from '@mui/icons-material/ShortText';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { QuestionType } from '../common/enums.js';
import { useAppContext } from '../../context/AppContext.jsx';
import CheckIcon from '@mui/icons-material/Check';
import CameraIcon from '@mui/icons-material/Camera';
import { ArrowCircleUp } from '@mui/icons-material';

const Survey = () => {
	const [loading, setLoading] = useState(true);
	const [openSpeedDial, setOpenSpeedDial] = useState(false);
	const [survey, setSurvey] = useState(null);
	const [hiddenQuestionStates, setHiddenQuestionStates] = useState({});
	const [hiddenBranchOptionStates, setHiddenBranchOptionStates] = useState({});
	const [complete, setComplete] = useState(false);
	const { user, openToast } = useAppContext();
	const { id } = useParams();

	const navigate = useNavigate();

	const goToFirstUnansweredQuestion = () => {
		const question = survey.questions?.find(
			(question) => !checkQuestionCompletion(question)
		);
		if (question) {
			const questionElement = document.getElementById(
				`question-${question.questionId}`
			);
			if (questionElement) {
				questionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	};

	const getSurvey = async () => {
		if (user.admin) {
			await getManagerSurvey();
		} else {
			await getDinerSurvey();
		}
	};
	const handleDelete = async () => {
		getSurvey();
	};
	const getManagerSurvey = async () => {
		try {
			const response = await axios.get(`survey/${id}`);
			setSurvey(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	const getDinerSurvey = async () => {
		try {
			const result = await axios.get(`survey/response/active/${user.userId}`);
			const surveyResponse = result.data[0];
			if (!surveyResponse) {
				setSurvey(null);
				navigate('/survey/invites');
			}
			const dinerSurveyResult = await axios.get(
				`survey/response/diner/${surveyResponse.surveyId}/${surveyResponse.surveyResponseId}`
			);
			setSurvey(dinerSurveyResult.data);
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		} finally {
			setLoading(false);
		}
	};
	const setIsHiddenQuestionCallback = (questionId, visibility) => {
		setHiddenQuestionStates((prevHiddenQuestionStates) => ({
			...prevHiddenQuestionStates,
			[questionId]: visibility,
		}));
	};
	const setIsHiddenBranchOptionCallback = (questionId, visibility) => {
		setHiddenBranchOptionStates((prevHiddenBranchOptionStates) => ({
			...prevHiddenBranchOptionStates,
			[questionId]: visibility,
		}));
	};
	const hasQuestionBranches = (question) => {
		if (question.questionBranches.length > 0) {
			return false;
		}
		return true;
	};
	useEffect(() => {
		getSurvey();
	}, []);

	useEffect(() => {
		if (survey) {
			setComplete(!surveyIncomplete());
		}
	}, [survey]);

	const surveyIncomplete = () => {
		if (user?.admin) {
			return false;
		}
		if (!survey || !survey?.questions || survey?.questions?.length === 0) {
			return true;
		} else {
			return survey.questions.some(
				(question) => !checkQuestionCompletion(question)
			);
		}
	};

	const checkQuestionCompletion = (question) => {
		const response = question.questionResponses[0];
		const isHiddenQuestion = hiddenQuestionStates[question.questionId] || false;
		if (isHiddenQuestion) {
			return true;
		}
		return (
			response.numberResponse ||
			response.numberResponse === 0 ||
			response.imagePath ||
			response.textResponseLong ||
			response.textResponseShort
		);
	};

	const handleSpeedDialAction = async (type) => {
		try {
			const question = new Question(
				survey.surveyId,
				type,
				survey?.questions?.length || 0
			);
			await axios.post('question/create', question);
			await getSurvey();
		} catch (error) {}
		handleSpeedDialClose();
	};

	const handleSpeedDialOpen = () => {
		setOpenSpeedDial(true);
	};

	const saveSurveyDetails = async (data, isTitle) => {
		let body = { surveyId: survey?.surveyId };
		if (isTitle) {
			body.surveyTitle = data;
		} else {
			body.surveyIntro = data;
		}
		try {
			const response = await axios.post('survey/save', body);
			getSurvey();
		} catch (err) {
			openToast(
				'Unable to process your request, please try again later',
				'error'
			);
			console.log(err);
		}
	};

	const handleSpeedDialClose = () => {
		setOpenSpeedDial(false);
	};

	const handleSubmitButtonClick = async () => {
		try {
			survey.surveyResponses[0].submitted = true;
			const response = await axios.post(
				'survey/response/submit',
				survey.surveyResponses[0]
			);
			openToast('Survey submitted successfully', 'success');
			getSurvey();
		} catch (err) {
			openToast(
				'Unable to process your request, please try again later',
				'error'
			);
			console.log(err);
		}
	};
	const getQuestionComponent = (type) => {
		switch (type) {
			case QuestionType.FIVE_STAR:
				return FiveStarRating;
			case QuestionType.SHORT_TEXT:
				return ShortTextResponse;
			case QuestionType.LONG_TEXT:
				return LongTextResponse;
			case QuestionType.MULTIPLE_CHOICE:
				return MultipleChoice;
			case QuestionType.SLIDING_SCALE:
				return SlidingScale;
			case QuestionType.YES_NO:
				return YesNo;
			case QuestionType.TIMER:
				return Timer;
			case QuestionType.PICTURE:
				return PictureResponse;
			default:
				return FiveStarRating;
		}
	};

	const speedDialActions = [
		{
			icon: <StarIcon />,
			type: QuestionType.FIVE_STAR,
			label: 'Five Star Rating',
		},
		{
			icon: <FormatListBulletedIcon />,
			type: QuestionType.MULTIPLE_CHOICE,
			label: 'Multiple Choice',
		},
		{ icon: <SubjectIcon />, type: QuestionType.LONG_TEXT, label: 'Long Text' },
		{
			icon: <ShortTextIcon />,
			type: QuestionType.SHORT_TEXT,
			label: 'Short Text',
		},
		{
			icon: <LinearScaleIcon />,
			type: QuestionType.SLIDING_SCALE,
			label: 'Sliding Scale',
		},
		{ icon: <TimerIcon />, type: QuestionType.TIMER, label: 'Timer' },
		{ icon: <CameraIcon />, type: QuestionType.PICTURE, label: 'Picture' },
		{ icon: <CheckIcon />, type: QuestionType.YES_NO, label: 'Yes or No' },
	];

	return loading || !survey ? (
		<Spinner />
	) : !survey ? (
		<div>You don't have an active survey!</div>
	) : (
		<Stack
			alignItems={'center'}
			spacing={2}
			className='max-h-[100vh] overflow-x-hidden overscroll-x-none overflow-y-scroll py-4 pb-20 no-scrollbar'>
			{user?.admin ? (
				<Card
					variant='outlined'
					className='w-full overflow-visible lg:max-w-[70%] max-w-[95%]'
					sx={{
						boxShadow:
							'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
					}}>
					<CardContent>
						<Box component='form' noValidate sx={{ mt: 1 }}>
							<TextField
								label='Survey Title'
								name='title'
								value={survey.surveyTitle}
								onChange={(e) => saveSurveyDetails(e.target.value, true)}
								fullWidth
								margin='normal'
							/>

							<TextField
								label='Survey Introduction'
								name='introduction'
								value={survey.surveyIntro}
								onChange={(e) => saveSurveyDetails(e.target.value, false)}
								multiline
								fullWidth
								margin='normal'
							/>
						</Box>
					</CardContent>
				</Card>
			) : (
				<Stack>
					<Typography className='self-start font-bold' variant='h4'>
						{survey.surveyTitle}
					</Typography>{' '}
					<Typography className='text-[#999999]' variant='xmd'>
						{survey.surveyIntro}
					</Typography>
				</Stack>
			)}
			{survey.questions
				.sort((a, b) => a.questionIndex - b.questionIndex)
				.map((question) => {
					const QuestionComponent = getQuestionComponent(question.questionType);
					const isHiddenQuestion =
						hiddenQuestionStates[question.questionId] || false;
					const isHiddenBranchOption =
						hiddenBranchOptionStates[question.questionId] === undefined
							? true && hasQuestionBranches(question)
							: hiddenBranchOptionStates[question.questionId];
					return (
						<QuestionCard
							key={question.questionId}
							question={question}
							onDelete={handleDelete}
							isHiddenQuestion={isHiddenQuestion}
							setIsHiddenQuestion={setIsHiddenQuestionCallback}
							setIsHiddenBranchOption={setIsHiddenBranchOptionCallback}
							QuestionComponent={QuestionComponent}
							questions={survey.questions}
							getSurvey={getSurvey}
							isHiddenBranchOption={isHiddenBranchOption}
						/>
					);
				})}
			{user.admin && (
				<SpeedDial
					ariaLabel='SpeedDial example'
					icon={<SpeedDialIcon />}
					sx={{ position: 'absolute', bottom: '6%', right: '3%' }}
					onClose={handleSpeedDialClose}
					onOpen={handleSpeedDialOpen}
					open={openSpeedDial}
					direction='up'>
					{speedDialActions.map((action) => (
						<SpeedDialAction
							key={action.type}
							icon={action.icon}
							tooltipTitle={action.label}
							onClick={() => handleSpeedDialAction(action.type)}
						/>
					))}
				</SpeedDial>
			)}
			{!user.admin && (
				<div className='flex justify-between'>
					<Button
						disabled={!complete}
						variant='contained'
						sx={{ borderRadius: '50px', padding: '10px 20px' }}
						onClick={handleSubmitButtonClick}>
						Submit your answers
					</Button>

					{!complete && (
						<IconButton color='error' onClick={goToFirstUnansweredQuestion}>
							<ArrowCircleUp fontSize='large' />
						</IconButton>
					)}
				</div>
			)}
		</Stack>
	);
};

export default Survey;
