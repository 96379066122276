import axios from '../../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext.jsx';
import Spinner from '../../common/spinner/spinner.jsx';
import { Stack, Typography, Button, IconButton } from '@mui/material';
import InviteCard from './InviteCard.jsx';
import Sheet from 'react-modal-sheet';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos, NotificationsActiveOutlined } from '@mui/icons-material';
import SurveyCard from './SurveyCard.jsx';
import NoSurveys from '../../../images/NoSurveys.png';
import { useGeolocation } from '@uidotdev/usehooks';
const SurveyInvitations = () => {
	const [loading, setLoading] = useState(true);
	const [invites, setInvites] = useState(null);
	const [selectedInvite, setSelectedInvite] = useState(null);
	const [currentSurvey, setCurrentSurvey] = useState(null);
	const [isBefore, setIsBefore] = useState(false);
	const { user } = useAppContext();
	const [open, setOpen] = useState(false);
	const location = useGeolocation();
	const navigate = useNavigate();
	const getActiveSurvey = async () => {
		try {
			const result = await axios.get(`survey/response/active/${user.userId}`);
			const surveyResponse = result.data[0];
			if (surveyResponse) {
				setCurrentSurvey(surveyResponse);
			}
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		}
	};

	const createSurveyResponse = async (invitation) => {
		try {
			const surveyResponse = {
				surveyId: invitation.surveyId,
				dinerId: invitation.dinerId,
				restaurantId: invitation.restaurantId,
				expirationDate: invitation.expirationDate,
			};
			const result = await axios.post(
				`/survey/response/create`,
				surveyResponse
			);
			if (result) {
				navigate(`/survey/respond`);
			}
		} catch (error) {
			console.error('ERROR CREATING SURVEY RESPONSE:\n' + error);
		}
	};

	const accept = async (invitation) => {
		try {
			const result = await axios.post(
				`/survey/invite/accept/${invitation.surveyInvitationId}`
			);
			if (result) {
				await createSurveyResponse(invitation);
			}
		} catch (error) {
			console.log('ERROR ACCEPTING SURVEY:\n' + error);
		}
	};

	useEffect(() => {
		if (!user.admin) {
			getActiveSurvey();
		}
	}, []);

	const getInvitations = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`survey/invite/diner/${user.userId}`);
			setInvites(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	const openDenyModal = (invitationId) => {
		setOpen(true);
		setSelectedInvite(invitationId);
	};
	const decline = async () => {
		try {
			const result = await axios.post(
				`/survey/invite/decline/${selectedInvite}`
			);
			if (result) {
				getInvitations();
			}
		} catch (error) {
			console.log('ERROR DECLINING SURVEY:\n' + error);
		}
		setOpen(false);
		setSelectedInvite(null);
	};

	const beforeAccept = (invitation) => {
		setSelectedInvite(invitation);
		setIsBefore(true);
	};

	useEffect(() => {
		getInvitations();
	}, []);

	const BottomModal = () => (
		<Sheet
			snapPoints={[0.5]}
			initialSnap={0}
			isOpen={open}
			onClose={() => setOpen(false)}>
			<Sheet.Container className=' rounded-md'>
				<Sheet.Header />
				<Sheet.Content>
					<Stack alignItems={'center'} spacing={2} sx={{ py: 2, px: 8 }}>
						<div className='rotate-[25deg]'>
							<NotificationsActiveOutlined
								sx={{ fontSize: '6rem' }}
								fontSize='inherit'
							/>
						</div>
						<Typography
							variant='h5'
							component='h5'
							className='font-bold text-center'>
							Are you sure you want to deny this survey?
						</Typography>
						<Typography variant='p' component='p' className='text-center'>
							Denying survey too often will result you being deprioritized of
							receiving survey in the future.
						</Typography>
						<Button
							variant='contained'
							color='error'
							sx={{ fontWeight: '600', py: '0.5rem', px: '2rem' }}
							onClick={decline}>
							Yes, I deny this survey.
						</Button>
						<Button
							onClick={() => setOpen(false)}
							variant='text'
							sx={{ color: 'black' }}>
							Let me think about it again
						</Button>
					</Stack>
				</Sheet.Content>
			</Sheet.Container>
			<Sheet.Backdrop />
		</Sheet>
	);

	const NoInvitations = () => {
		return (
			<Stack
				alignItems={'center'}
				spacing={2}
				className='max-h-[100vh] w-full overflow-y-scroll pb-40 flex-grow justify-center'>
				<Stack
					direction='column'
					spacing={2}
					className='justify-center w-[300px]'>
					<img
						width='200px'
						height='200px'
						src={NoSurveys}
						className='self-center'
						alt='No Survey Invitations'
					/>
					<Typography className='text-center' variant='smTitle'>
						You don’t have any surveys assigned to you yet.
					</Typography>
					<Typography className='text-center' variant='smd'>
						When you do, you will see them here.
					</Typography>
				</Stack>
			</Stack>
		);
	};

	return isBefore ? (
		<div className='max-h-[100vh] flex-grow overflow-y-scroll -m-[1.5rem] p-4 before-survey-background pb-24 flex flex-col justify-between'>
			<div>
				<IconButton
					onClick={() => {
						setSelectedInvite(null);
						setIsBefore(false);
					}}
					className='text-[#292929] h-6 w-6'>
					<ArrowBackIos />
				</IconButton>
			</div>
			<Stack spacing={8}>
				<Stack>
					<Typography className='before-survey-heading text-[#485EEA]'>
						Before you start.
					</Typography>
					<Typography variant='xmd'>
						You will need to upload a receipt to complete this survey.
						Additionally, some questions require you to take photos, so please
						ensure that camera access is enabled in your device settings.
					</Typography>
				</Stack>
				<Stack spacing={2} alignItems='center'>
					<Button
						variant='contained'
						sx={{
							fontWeight: '600',
							py: '0.5rem',
							px: '2rem',
							backgroundColor: '#485EEA',
							borderRadius: '1rem',
							width: '300px',
						}}
						onClick={() => accept(selectedInvite)}>
						Take the survey
					</Button>
					<Button
						onClick={() => setOpen(false)}
						variant='text'
						sx={{ color: 'black' }}>
						Open settings
					</Button>
				</Stack>
			</Stack>
		</div>
	) : loading ? (
		<Spinner />
	) : !!(invites && invites.length > 0) || currentSurvey ? (
		<Stack
			alignItems={'center'}
			spacing={2}
			className='max-h-[100vh] overflow-y-scroll pb-40'>
			<Typography className='self-start font-bold' variant='h4'>
				Surveys
			</Typography>
			{currentSurvey && (
				<SurveyCard survey={currentSurvey} location={location} />
			)}
			{!currentSurvey && invites && (
				<Typography variant='xmd' className='self-start text-[#444444]'>
					Only one survey will be assigned to you at a time, please review the
					details of the survey and make your decision timely, someone else is
					always trying to accept the survey while you are considering.
				</Typography>
			)}
			{!currentSurvey &&
				invites &&
				invites.map((invitation) => {
					return (
						<InviteCard
							accept={beforeAccept}
							key={invitation.surveyInvitationId}
							invitation={invitation}
							openDenyModal={openDenyModal}
							location={location}></InviteCard>
					);
				})}
			<BottomModal />
		</Stack>
	) : (
		<NoInvitations />
	);
};

export default SurveyInvitations;
