import { Rating, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import axios from '../../../services/auth-header.js';
import debounce from '../../../utils/debounce.js';
import { Star } from '@mui/icons-material';

const FiveStarRating = ({ question, getSurvey }) => {
	const [isCreate, setIsCreate] = useState(true);
	const { user } = useAppContext();
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [response, setResponse] = useState(
		question.questionResponses?.[0]?.numberResponse || 0
	);

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				let response = await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const handleResponseChange = async (e, newVal) => {
		setResponse(newVal);
		try {
			await axios.post('question/response/save', {
				...question,
				numberResponse: newVal,
				dinerId: question.dinerId,
				surveyResponseId: question.surveyResponseId,
				questionResponseId: question.questionResponseId,
			});
			getSurvey();
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		setIsCreate(user.admin);
	}, []);

	return (
		<Stack>
			{isCreate && (
				<TextField
					className='question-text mb-4'
					value={questionText}
					onChange={handleQuestionTextChange}
					variant='outlined'
					fullWidth
					multiline
					rows={1}
					placeholder={'Enter your question here'}
				/>
			)}
			{!isCreate && (
				<Typography
					variant='sm'
					className='text-[#555555] text-start mb-4 -mt-2'>
					5 stars being the highest.
				</Typography>
			)}
			<Stack alignItems='center'>
				<Rating
					className='rating-star'
					value={response}
					onChange={handleResponseChange}
					size='large'
					sx={{ fontSize: '45px' }}
					disabled={isCreate}
					emptyIcon={<Star style={{ opacity: 0.55 }} fontSize='inherit' />}
				/>
			</Stack>
		</Stack>
	);
};

export default FiveStarRating;
