import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import { logout } from '../../services/auth-header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { useEffect, useState } from 'react';
import axios from '../../services/auth-header.js';
import { sidebar } from './sidebarItems'; // Import the updated sidebar items

function Sidebar({ drawerWidth, mobileOpen, setMobileOpen, setIsClosing }) {
	const { user } = useAppContext();
	const [selectedTab, setSelectedTab] = useState();
	const [activeSurvey, setActiveSurvey] = useState(false);
	const location = useLocation();

	const navigate = useNavigate();

	const handleDrawerClose = () => {
		setIsClosing(true);
		setMobileOpen(false);
	};

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false);
	};

	const doLogout = async () => {
		navigate('/');
		logout();
	};

	const getActiveSurvey = async () => {
		try {
			const result = await axios.get(`survey/response/active/${user.userId}`);
			const surveyResponse = result.data[0];
			if (surveyResponse) {
				setActiveSurvey(true);
			} else {
				setActiveSurvey(false);
				console.log('No active survey');
			}
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		}
	};

	useEffect(() => {
		const pathname = location.pathname;
		if (
			pathname === '/admin/survey/templates' ||
			/^\/admin\/survey\/\d+$/.test(pathname)
		) {
			setSelectedTab('Templates');
		} else if (pathname === '/admin/survey/manage') {
			setSelectedTab('Manage');
		} else if (
			pathname === '/admin/restaurant/manage' ||
			/^\/admin\/restaurant\/\d+$/.test(pathname)
		) {
			setSelectedTab('Restaurants');
		} else if (
			pathname === '/admin/survey/statistics' ||
			/^\/admin\/survey\/responses\/\d+$/.test(pathname)
		) {
			setSelectedTab('Statistics');
		} else if (pathname === '/survey/invites') {
			setSelectedTab('Invites');
		} else if (pathname === '/survey/respond') {
			setSelectedTab('Respond');
		} else if (pathname === '/survey/create') {
			setSelectedTab('Create');
		} else if (pathname === '/diner/settings') {
			setSelectedTab('Settings');
		} else if (pathname === '/admin/groups') {
			// Handle Groups Playground
			setSelectedTab('Groups');
		} else if (pathname === '/operatorTools') {
			setSelectedTab('Operator Tools');
		} else {
			if (user.admin) {
				setSelectedTab('Manage');
			} else if (activeSurvey) {
				setSelectedTab('Respond');
			} else {
				setSelectedTab('Invites');
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		if (!user.admin) {
			getActiveSurvey();
		}
	}, [user]);

	const canSeeTab = (sidebarItem) => {
		return (
			(user.superAdmin && sidebarItem.superAdmin) ||
			(user.admin && sidebarItem.isAdmin) ||
			(!user.admin &&
				!sidebarItem.isAdmin &&
				((sidebarItem.selected === 'Respond' && activeSurvey) ||
					(sidebarItem.selected === 'Invites' && !activeSurvey)))
		);
	};

	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List>
				{sidebar.map((sidebarItem, index) => {
					return (
						canSeeTab(sidebarItem) && (
							<ListItem
								disablePadding
								key={index}
								component={Link}
								to={sidebarItem.path}
								selected={selectedTab === sidebarItem.selected}
								onClick={() => setSelectedTab(sidebarItem.selected)}>
								<ListItemButton
									sx={{
										backgroundColor:
											selectedTab === sidebarItem.selected
												? 'rgba(0, 0, 0, 0.08)'
												: 'transparent',
									}}>
									<ListItemIcon>
										<sidebarItem.icon />
									</ListItemIcon>
									<ListItemText>{sidebarItem.text}</ListItemText>
								</ListItemButton>
							</ListItem>
						)
					);
				})}
			</List>
			<Divider />
			<ListItem onClick={doLogout} disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText>Logout</ListItemText>
				</ListItemButton>
			</ListItem>
		</div>
	);

	return (
		<Box
			component='nav'
			sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
			aria-label='mailbox folders'>
			<Drawer
				variant='temporary'
				open={mobileOpen}
				onTransitionEnd={handleDrawerTransitionEnd}
				onClose={handleDrawerClose}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}>
				{drawer}
			</Drawer>
			<Drawer
				variant='permanent'
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}
				open>
				{drawer}
			</Drawer>
		</Box>
	);
}

export default Sidebar;
