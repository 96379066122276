import { useAppContext } from '../../../context/AppContext';
import {
	Card,
	CardContent,
	IconButton,
	CardActions,
	Container,
	Menu,
	MenuItem,
	ListSubheader,
	Tooltip,
	Stack,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from '../../../services/auth-header';
import { useEffect, useRef, useState } from 'react';

const QuestionCard = ({
	question,
	onDelete,
	isHiddenQuestion,
	setIsHiddenQuestion,
	setIsHiddenBranchOption,
	QuestionComponent,
	questions,
	getSurvey,
	isHiddenBranchOption,
}) => {
	const { user } = useAppContext();
	const [anchorEl, setAnchorEl] = useState(null);
	const pinnedRef = useRef(null);
	const [branchableQuestion, setBranchableQuestion] = useState(false);
	const [pinned, setPinned] = useState(false);
	const [timerActive, setTimerActive] = useState(false);
	const handleDelete = async (event) => {
		event.stopPropagation();
		try {
			const response = await axios.delete(
				`question/delete/${question.questionId}`
			);
			onDelete();
		} catch (err) {
			console.error(err);
		}
	};

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const getConditionalQuestionResponse = async (questionId) => {
		try {
			const response = await axios.get(
				`question/response/${question.questionId}/${user.userId}`
			);
			return response.data;
		} catch (err) {
			console.error(err);
		}
	};

	const handleBranchableQuestion = () => {
		if (
			question.questionType === 'MULTIPLE_CHOICE' ||
			question.questionType === 'YES_NO'
		) {
			setBranchableQuestion(true);
		} else {
			setBranchableQuestion(false);
		}
	};

	const handleAddBranchOption = () => {
		handleMenuClose();
		setIsHiddenBranchOption(question.questionId, false);
	};

	useEffect(() => {
		const fetchData = async () => {
			let newVisibility = question.conditionalQuestion && !user.admin;

			if (question.conditionalQuestion && !user.admin) {
				try {
					const questionResponse = await getConditionalQuestionResponse(
						question.conditionalQuestion
					);
					if (
						!(
							questionResponse.numberResponse === null &&
							questionResponse.textResponseShort === null &&
							questionResponse.textResponseLong === null
						)
					) {
						newVisibility = false;
					}
				} catch (err) {
					console.error(err);
				}
			}

			if (newVisibility !== isHiddenQuestion) {
				setIsHiddenQuestion(question.questionId, newVisibility);
			}
		};
		fetchData();
		handleBranchableQuestion();
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (timerActive && pinned !== !entry.isIntersecting) {
					setPinned(!entry.isIntersecting);
				}
			},
			{ threshold: 1 }
		);

		if (pinnedRef.current) {
			observer.observe(pinnedRef.current);
		}

		return () => observer.disconnect();
	}, [timerActive]);

	useEffect(() => {
		if (!timerActive && pinned) {
			setPinned(false);
		}
	}, [timerActive]);

	return (
		<Card
			id={`question-${question.questionId}`}
			variant='outlined'
			ref={pinnedRef}
			className={`w-full overflow-visible lg:max-w-[70%] transition-all duration-300 ${
				pinned ? 'sticky bottom-0 top-0 shadow-lg z-50' : ''
			}`}
			style={{
				display: isHiddenQuestion ? 'none' : 'block',
			}}
			sx={{
				boxShadow:
					'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
			}}>
			<CardContent>
				<Stack spacing={1}>
					<Typography className='text-[#222222]' variant='qText'>
						{question.questionText}
					</Typography>
					<QuestionComponent
						question={question}
						questions={questions}
						getSurvey={getSurvey}
						setIsHiddenQuestion={setIsHiddenQuestion}
						isHiddenBranchOption={isHiddenBranchOption}
						setTimerActive={setTimerActive}
					/>
				</Stack>
			</CardContent>
			{user.admin && (
				<CardActions className='flex justify-end'>
					{user.admin && branchableQuestion && (
						<>
							<Tooltip title='More Options'>
								<IconButton onClick={handleMenuOpen}>
									<MoreVertIcon />
								</IconButton>
							</Tooltip>
							<Menu
								id='simple-menu'
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}>
								<ListSubheader>Options</ListSubheader>
								<MenuItem onClick={handleAddBranchOption}>
									Go to question based on answer
								</MenuItem>
							</Menu>
						</>
					)}
					{user.admin && (
						<CardActions className='flex justify-end'>
							<IconButton onClick={handleDelete}>
								<DeleteIcon />
							</IconButton>
						</CardActions>
					)}
				</CardActions>
			)}
		</Card>
	);
};

export default QuestionCard;
