import { getDistance } from 'geolib';

export const getDistanceBetweenTwoCoords = (lon1, lat1, lon2, lat2) => {
	return (
		getDistance(
			{ latitude: lat1, longitude: lon1 },
			{ latitude: lat2, longitude: lon2 }
		) / 1609.34
	)?.toFixed(1);
};
