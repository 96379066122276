import { useEffect, useState } from 'react';
import { useAppContext } from '../../../../context/AppContext.jsx';
import axios from '../../../../services/auth-header.js';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelIcon from '@mui/icons-material/Cancel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
	Button,
	Card,
	Stack,
	TextField,
	Typography,
	IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useNavigate } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const getFrequencyString = (frequency) => {
	switch (frequency) {
		case 7:
			return 'Weekly';
		case 14:
			return 'Bi-weekly';
		case 30:
			return 'Monthly';
		default:
			return '-';
	}
};

const columns = [
	{ id: 'restaurant', label: 'Restaurant', minWidth: '15%', align: 'left' },
	{
		id: 'surveyTemplate',
		label: 'Survey Title',
		minWidth: '15%',
		align: 'left',
	},
	{ id: 'frequency', label: 'Frequency', align: 'left', minWidth: '15%' },
	{
		id: 'participantsPerFrequency',
		label: 'Requested Diners',
		align: 'right',
		minWidth: '15%',
	},
	{ id: 'invitesSent', label: 'Invites Sent', align: 'right', minWidth: '15%' },
	{
		id: 'acceptanceRate',
		label: 'Acceptance Rate',
		align: 'right',
		minWidth: '15%',
	},
	// {
	// 	id: 'inProgress',
	// 	label: 'In Progress',
	// 	align: 'right',
	// 	minWidth: '15%',
	// },
	// {
	// 	id: 'submitted',
	// 	label: 'Submitted',
	// 	align: 'right',
	// 	minWidth: '15%',
	// },
	{
		id: 'completedSurveys',
		label: 'Responses',
		align: 'right',
		minWidth: '15%',
	},
	{ id: 'activeStatus', label: 'Status', align: 'center', minWidth: '15%' },
	{ id: 'actions', label: 'Actions', align: 'center', minWidth: '15%' },
	// { id: 'download', label: 'Response Data', minWidth: '15%', align: 'center' },
	// { id: 'active', label: 'Active', minWidth: '15%', align: 'center' },
	// { id: 'delete', label: 'Delete', minWidth: '15%', align: 'center' },
];

const SurveyStatistics = () => {
	const { user } = useAppContext();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchTermRestaurant, setSearchTermRestaurant] = useState('');
	const [searchTermTemplate, setSearchTermTemplate] = useState('');
	const [surveyData, setSurveyData] = useState([]);
	const [openEndSurveyConfirmation, setOpenEndSurveyConfirmation] =
		useState(false);
	const [openResumeConfirmation, setOpenResumeConfirmation] = useState(false);
	const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
	const [selectedSurvey, setSelectedSurvey] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();

	useEffect(() => {
		getSurveyData();
	}, []);

	const handleCloseConfirmation = () => {
		setOpenEndSurveyConfirmation(false);
		setOpenDeleteConfirmation(false);
		setOpenResumeConfirmation(false);
	};

	const handleMoreClick = async (event, row) => {
		setSelectedRow(row);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleResumeSurveyClick = async (row) => {
		try {
			setSelectedSurvey(row);
			setOpenResumeConfirmation(true);
		} catch (err) {
			console.log(err);
		}
	};

	const handleConfirmResumeSurveyClick = async (row) => {
		const response = await axios.post(
			`survey/activate/${row.recurringSurvey.recurringSurveyId}`
		);
		if (response.status === 200) {
			getSurveyData();
		}
		handleCloseConfirmation();
	};

	const handleEndSurveyClick = async (row) => {
		try {
			setSelectedSurvey(row);
			setOpenEndSurveyConfirmation(true);
		} catch (err) {
			console.log(err);
		}
	};

	const handleConfirmEndSurveyClick = async (row) => {
		try {
			const response = await axios.post(
				`survey/disable/${row.recurringSurvey.recurringSurveyId}`
			);
			if (response.status === 200) {
				getSurveyData();
			}
			handleCloseConfirmation();
		} catch (err) {
			console.log(err);
		}
	};

	const handleDeleteSurveyDataClick = async (row) => {
		try {
			setSelectedSurvey(row);
			setOpenDeleteConfirmation(true);
		} catch (err) {
			console.log(err);
		}
	};

	const handleConfirmDeleteSurveyClick = async (row) => {
		const response = await axios.delete(
			`survey/recurring/${row.recurringSurvey.recurringSurveyId}`
		);
		if (response.status === 200) {
			getSurveyData();
		}
		handleCloseConfirmation();
	};

	const getSurveyData = async () => {
		const response = await axios.get(`survey/response/data/${user.userId}`);
		let data = [];
		let i = 0;
		for (const surveyInstance of response.data) {
			const item = createRow(surveyInstance, i);
			i++;
			data.push(item);
		}
		setSurveyData(data);
	};

	const createRow = (instance, i) => {
		const key = i;

		const frequency = instance.frequency;
		const participantsPerFrequency = instance.surveyorsRequested;
		const active = instance.active;
		const activeStatus = active ? 'Active' : 'Inactive';
		const completedSurveys = instance.survey.surveyResponses.length;
		const restaurantObject = instance.restaurant;
		const restaurant =
			restaurantObject.restaurantName + ' - ' + restaurantObject.city;
		const surveyTemplateObject = instance.survey;
		const surveyTemplate = surveyTemplateObject.surveyTitle;
		const surveyResponses = surveyTemplateObject.surveyResponses;
		const recurringSurvey = instance;
		const invitesSent = instance.totalSent;
		let acceptanceRate;
		if (!isNaN(completedSurveys) && !isNaN(invitesSent) && invitesSent !== 0) {
			acceptanceRate =
				((completedSurveys / invitesSent) * 100).toFixed(2) + '%';
		} else {
			acceptanceRate = 'N/A';
		}
		const inProgress = instance.survey.surveyResponses.reduce(
			(count, response) => {
				return count + (response.submitted ? 0 : 1);
			},
			0
		);
		const submitted = instance.survey.surveyResponses.reduce(
			(count, response) => {
				return count + (response.submitted ? 1 : 0);
			},
			0
		);

		return {
			restaurant,
			surveyTemplate,
			frequency,
			participantsPerFrequency,
			active,
			completedSurveys,
			key,
			restaurantObject,
			surveyTemplateObject,
			surveyResponses,
			recurringSurvey,
			invitesSent,
			acceptanceRate,
			inProgress,
			submitted,
			activeStatus,
		};
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const filteredRows = surveyData.filter(
		(row) =>
			row.restaurant
				.toLowerCase()
				.includes(searchTermRestaurant.toLowerCase()) &&
			row.surveyTemplate
				.toLowerCase()
				.includes(searchTermTemplate.toLowerCase())
	);

	const formatCSVRow = (surveyResponse) => {
		let row = {};
		row['Diner ID'] = surveyResponse.dinerId;

		row['Diner Email'] = surveyResponse.diner.email;
		row['Diner Name'] =
			surveyResponse.diner.firstName === null ||
			surveyResponse.diner.lastName === null
				? 'Name not provided'
				: surveyResponse.diner.firstName + ' ' + surveyResponse.diner.lastName;
		row['Diner Gender'] = surveyResponse.diner.gender;
		row['Diner ZipCode'] = surveyResponse.diner.zipCode.zipCode;

		row['Survey Response Id'] = surveyResponse.surveyResponseId;
		row['Completed Survey'] = surveyResponse.submitted ? 'Yes' : 'No';
		if (surveyResponse.submitted) {
			row['Date Completed'] = surveyResponse.updatedDate;
		} else {
			row['DateCompleted'] = 'Survey not completed';
		}

		for (let i = 0; i < surveyResponse.questionResponses.length; i++) {
			const questionResponse = surveyResponse.questionResponses[i];
			let questionText = questionResponse.question.questionText;
			questionText = questionText + '"';
			const column = '"' + (i + 1) + ': ' + questionText;
			const questionType = questionResponse.question.questionType;
			if (
				questionResponse.question.conditionalQuestion &&
				questionResponse.numberResponse === null &&
				questionResponse.textResponseLong === null &&
				questionResponse.textResponseShort === null
			) {
				row[column] = 'Skipped (Conditional Question)';
			} else {
				if (questionType === 'YES_NO') {
					row[column] = questionResponse.numberResponse === 0 ? 'No' : 'Yes';
				} else if (questionType === 'MULTIPLE_CHOICE') {
					const choice = questionResponse.question.questionChoices?.find(
						(opt) => opt.choiceIndex === questionResponse.numberResponse
					)?.choiceText;
					row[column] = choice;
				} else if (
					questionType === 'FIVE_STAR' ||
					questionType === 'SLIDING_SCALE' ||
					questionType === 'TIMER'
				) {
					row[column] = questionResponse.numberResponse;
				} else if (questionType === 'PICTURE') {
					row[column] =
						process.env.REACT_APP_S3_BUCKET_URL + questionResponse.imagePath;
				} else {
					let textResponse = questionResponse.textResponseLong
						? questionResponse.textResponseLong
						: questionResponse.textResponseShort;
					textResponse = '"' + textResponse + '"';
					row[column] = textResponse;
				}
			}
		}
		return row;
	};

	const convertToCSV = (objArray) => {
		const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';

		const headers = Object.keys(array[0]);
		str += headers.join(',') + '\r\n';

		for (let i = 0; i < array.length; i++) {
			let line = '';
			for (let index in array[i]) {
				if (line !== '') line += ',';
				line += array[i][index];
			}
			str += line + '\r\n';
		}
		return str;
	};

	const downloadCSV = (csv, fileName) => {
		const blob = new Blob([csv], { type: 'text/csv' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	const handleDownloadClick = (row) => {
		const surveyResponses = row.surveyResponses;
		let formattedResponses = [];

		for (const response of surveyResponses) {
			formattedResponses.push(formatCSVRow(response));
		}
		const fileName = `${row.restaurant} - ${row.surveyTemplate}`;
		const csv = convertToCSV(formattedResponses);
		downloadCSV(csv, fileName);
	};

	const handleViewResponsesClick = async (row) => {
		navigate(
			`/admin/survey/responses/${row.recurringSurvey.recurringSurveyId}`
		);
	};

	return (
		<Stack sx={{ marginTop: '2%', minHeight: 0, flexGrow: 1 }}>
			<Stack
				direction='row'
				sx={{
					alignContent: 'center',
					justifyContent: 'space-between',
					marginBottom: '2%',
				}}>
				<Typography sx={{ alignContent: 'center' }} variant='h4'>
					Survey Data
				</Typography>
				<Stack
					direction='row'
					sx={{ justifyContent: 'space-between', width: '50%' }}>
					<Card
						sx={{
							width: '45%',
						}}>
						<TextField
							label='Filter by Restaurant'
							variant='outlined'
							value={searchTermRestaurant}
							onChange={(e) => setSearchTermRestaurant(e.target.value)}
							fullWidth
						/>
					</Card>
					<Card
						sx={{
							width: '45%',
						}}>
						<TextField
							label='Filter by Survey Title'
							variant='outlined'
							value={searchTermTemplate}
							onChange={(e) => setSearchTermTemplate(e.target.value)}
							fullWidth
						/>
					</Card>
				</Stack>
			</Stack>
			<Paper
				square={false}
				elevation={3}
				sx={{ minHeight: 0, height: 'fit-content' }}>
				<TableContainer sx={{ height: 'fit-content' }}>
					<Table stickyHeader aria-label='sticky table'>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredRows
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow hover key={row.key}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<TableCell key={column.id} align={column.align}>
													{column.id === 'download' ? (
														<Button
															variant='contained'
															startIcon={<FileDownloadIcon />}
															onClick={() => handleDownloadClick(row)}
															disabled={
																row['completedSurveys'] === 0 ? true : false
															}>
															Download
														</Button>
													) : column.id === 'active' ? (
														value ? (
															<Button
																variant='contained'
																color='error'
																startIcon={<CancelIcon />}
																onClick={() => handleEndSurveyClick(row)}>
																End Survey
															</Button>
														) : (
															<Button
																variant='contained'
																color='success'
																startIcon={<RestartAltIcon />}
																onClick={() => handleResumeSurveyClick(row)}
																// disabled={true}
															>
																Resume Survey
															</Button>
														)
													) : column.id === 'delete' ? (
														<Button
															variant='contained'
															color='error'
															onClick={() => handleDeleteSurveyDataClick(row)}>
															Delete
														</Button>
													) : column.id === 'frequency' ? (
														getFrequencyString(value) // Use the getFrequencyString function to map the frequency
													) : column.format && typeof value === 'number' ? (
														column.format(value)
													) : column.id === 'activeStatus' ? (
														<span
															style={{
																color: value === 'Active' ? 'green' : 'red',
															}}>
															{value}
														</span>
													) : column.id === 'actions' ? (
														<IconButton
															aria-controls={open ? 'fade-menu' : undefined}
															aria-haspopup='true'
															aria-expanded={open ? 'true' : undefined}
															onClick={(event) => handleMoreClick(event, row)}>
															<MoreHorizIcon />
														</IconButton>
													) : (
														value
													)}
												</TableCell>
											);
										})}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component='div'
					count={filteredRows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			<Dialog
				open={openEndSurveyConfirmation}
				onClose={handleCloseConfirmation}>
				<DialogTitle>End Survey</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to end the survey for{' '}
						{selectedSurvey && selectedSurvey.restaurant} -{' '}
						{selectedSurvey && selectedSurvey.surveyTemplate}? <br />
						<br />
						All diners who have received an invitation for this survey will
						still be able to accept and receive their reward.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirmation} variant='contained'>
						Cancel
					</Button>
					<Button
						onClick={() => handleConfirmEndSurveyClick(selectedSurvey)}
						color='error'
						variant='contained'>
						End Survey
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={openResumeConfirmation} onClose={handleCloseConfirmation}>
				<DialogTitle>Resume Survey</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to resume the survey for{' '}
						{selectedSurvey && selectedSurvey.restaurant} -{' '}
						{selectedSurvey && selectedSurvey.surveyTemplate}? <br />
						<br />
						Any active surveys for this restaurant will be cancelled. All diners
						who have a received an invitation for cancelled surveys will still
						be able to accept and receive their reward.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirmation} variant='contained'>
						Cancel
					</Button>
					<Button
						onClick={() => handleConfirmResumeSurveyClick(selectedSurvey)}
						color='error'
						variant='contained'>
						Resume Survey
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={openDeleteConfirmation} onClose={handleCloseConfirmation}>
				<DialogTitle>Delete Survey Data</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to delete the survey data for{' '}
						{selectedSurvey && selectedSurvey.restaurant} -{' '}
						{selectedSurvey && selectedSurvey.surveyTemplate}? <br />
						<br />
						All survey responses related to this survey will be deleted
						permanently. This action cannot be undone. <br />
						<br />
						All diners who have received an invitation for this survey will
						still be able to accept and receive their reward.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirmation} variant='contained'>
						Cancel
					</Button>
					<Button
						onClick={() => handleConfirmDeleteSurveyClick(selectedSurvey)}
						color='error'
						variant='contained'>
						Delete Survey Data
					</Button>
				</DialogActions>
			</Dialog>
			<Menu
				id='fade-menu'
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}>
				{/* Conditionally render the "Download Responses" MenuItem based on the number of completed surveys */}
				{selectedRow['completedSurveys'] !== 0 ? (
					<MenuItem
						onClick={() => {
							handleClose();
							handleDownloadClick(selectedRow);
						}}>
						Download Responses
					</MenuItem>
				) : null}

				{selectedRow.active ? (
					<MenuItem
						onClick={() => {
							handleClose();
							handleEndSurveyClick(selectedRow);
						}}>
						End Survey
					</MenuItem>
				) : (
					<MenuItem
						onClick={() => {
							handleClose();
							handleResumeSurveyClick(selectedRow);
						}}>
						Resume Survey
					</MenuItem>
				)}

				{/* Always render the "Delete Data" MenuItem */}
				<MenuItem
					onClick={() => {
						handleClose();
						handleDeleteSurveyDataClick(selectedRow);
					}}>
					Delete Data
				</MenuItem>
				{selectedRow['completedSurveys'] !== 0 ? (
					<MenuItem
						onClick={() => {
							handleClose();
							handleViewResponsesClick(selectedRow);
						}}>
						View Responses
					</MenuItem>
				) : null}
			</Menu>
		</Stack>
	);
};

export default SurveyStatistics;
