import { Stack, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext';
import axios from '../../../services/auth-header.js';
import debounce from '../../../utils/debounce.js';

const LongTextResponse = ({ question, getSurvey }) => {
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [response, setResponse] = useState(
		question.questionResponses?.[0]?.textResponseLong || ''
	);
	const [isCreate, setIsCreate] = useState(true);
	const { user } = useAppContext();

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				let response = await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const handleResponseChange = async (e) => {
		const newTextResponse = e.target.value;
		setResponse(newTextResponse);
		debounce(async () => {
			try {
				let response = await axios.post('question/response/save', {
					...question,
					textResponseLong: newTextResponse,
					dinerId: question.dinerId,
					surveyResponseId: question.surveyResponseId,
					questionResponseId: question.questionResponseId,
				});
				getSurvey();
			} catch (err) {
				console.error(err);
			}
		})();
	};

	useEffect(() => {
		setIsCreate(user.admin);
	}, []);

	return (
		<Stack alignItems={'center'} spacing={2}>
			{isCreate && (
				<TextField
					className='question-text'
					value={questionText}
					onChange={handleQuestionTextChange}
					variant='outlined'
					fullWidth
					multiline
					rows={2}
					placeholder={'Enter your question here'}
				/>
			)}
			<TextField
				className='text-response'
				value={response}
				onChange={handleResponseChange}
				variant='outlined'
				disabled={isCreate}
				fullWidth
				multiline
				rows={isCreate ? 8 : 20}
				placeholder={
					isCreate
						? 'Diner enters their response here'
						: 'Provide your answer here (max 500 characters)'
				}
				inputProps={{ maxLength: 512 }}
			/>
		</Stack>
	);
};

export default LongTextResponse;
