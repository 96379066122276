import {
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Avatar,
  Typography,
} from "@mui/material";
import axios from "../../../services/auth-header.js";
import { useEffect, useState } from "react";
import { getDistanceBetweenTwoCoords } from "../../common/location-utils.js";

const InviteCard = ({ invitation, openDenyModal, accept, location }) => {
  const getGoogleMapsLink = (address) =>
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
  const [survey, setSurvey] = useState(null);
  const [restaurant, setRestaurant] = useState(null);

  const getSurvey = async () => {
    try {
      const result = await axios.get(`survey/${invitation.surveyId}`);
      const surveyResponse = result.data;
      if (!surveyResponse) {
        setSurvey(null);
      } else {
        setSurvey(result.data);
      }
    } catch (error) {
      console.error("ERROR FETCHING SURVEY:\n" + error);
    }
  };

  const getRestaurant = async () => {
    try {
      const result = await axios.get(`restaurant/${invitation.restaurantId}`);
      const restaurantResponse = result.data;
      if (!restaurantResponse) {
        setRestaurant(null);
      } else {
        setRestaurant(result.data);
      }
    } catch (error) {
      console.error("ERROR FETCHING RESTAURANT:\n" + error);
    }
  };

  useEffect(() => {
    getRestaurant();
    getSurvey();
  }, []);

  return (
    <Card
      variant="outlined"
      className="w-full overflow-visible py-4"
      style={{ maxWidth: "32rem" }}
    >
      <CardContent>
        <Container>
          <Stack>
            <Stack direction="row" spacing={4}>
              {restaurant && (
                <Avatar
                  alt={`${restaurant.restaurantName} Logo`}
                  src={
                    process.env.REACT_APP_S3_BUCKET_URL + restaurant?.logoPath
                  }
                  sx={{ width: 72, height: 72 }}
                />
              )}
              <Stack>
                <Typography variant="smTitle">
                  {restaurant && restaurant.restaurantName}
                </Typography>
                <Typography variant="md">
                  {restaurant && (
                    <a
                      href={getGoogleMapsLink(
                        `${restaurant.streetAddress} ${restaurant.city}, ${restaurant.stateAbbreviation} ${restaurant.zipCode}`
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${restaurant.streetAddress} ${restaurant.city}, ${restaurant.stateAbbreviation} ${restaurant.zipCode}`}
                    </a>
                  )}
                </Typography>
                {location?.longitude && restaurant?.restaurantLocation && (
                  <Typography variant="hsm" className="text-[#999999]">
                    {`${getDistanceBetweenTwoCoords(
                      location?.longitude,
                      location?.latitude,
                      parseFloat(restaurant?.restaurantLocation.longitude),
                      parseFloat(restaurant?.restaurantLocation.latitude)
                    )} miles away`}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <div className="border-[#C6C6C6] border-[0.5px] my-4"></div>
            <Stack direction="row" spacing={8}>
              <Stack spacing={0.25}>
                <Typography variant="xsm" className="font-semibold">
                  Rewards
                </Typography>
                <Typography variant="sm">
                  {`$${survey?.rewardAmount} value`}
                </Typography>
                <Typography variant="xsm" className="text-[#999999]">
                  Support by Tremendous
                </Typography>
              </Stack>
              <Stack spacing={0.25}>
                <Typography variant="xsm" className="font-semibold">
                  Length of the Survey
                </Typography>
                <Typography variant="sm">
                  {`${survey?.questions?.length} Questions`}
                </Typography>
                <Typography variant="xsm" className="text-[#999999]">
                  {`Estimate ${survey?.questions?.length} minutes`}
                </Typography>
              </Stack>
            </Stack>
            {/* Actually get a tailored description */}
            <Typography variant="sm" className="text-[#555555] mt-6">
              The survey contains multiple choice, long/short typing, and photo
              taking, so we suggest you to start the survey upon the arrival of
              the restaurant.
            </Typography>
            <div className="w-full flex justify-center mt-6 space-x-12">
              <Button
                className="w-[7.938rem] h-[2.75rem]"
                variant="outlined"
                onClick={() => openDenyModal(invitation.surveyInvitationId)}
              >
                Reject
              </Button>
              <Button
                className="w-[7.938rem] h-[2.75rem]"
                variant="contained"
                onClick={() => accept(invitation)}
              >
                Accept
              </Button>
            </div>
          </Stack>
        </Container>
      </CardContent>
    </Card>
  );
};

export default InviteCard;
