import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
	TextField,
	Button,
	IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useEffect } from 'react';
import axios from '../../../services/auth-header.js';
import { useAppContext } from '../../../context/AppContext';
import debounce from '../../../utils/debounce.js';
import { QuestionChoice } from '../../../models/QuestionChoice.js';
import QuestionBranch from '../../manager/QuestionBranch.jsx';

const MultipleChoice = ({
	question,
	questions,
	getSurvey,
	setIsHiddenQuestion,
	isHiddenBranchOption,
}) => {
	const [selectedOption, setSelectedOption] = useState(null);
	const [isCreate, setIsCreate] = useState(true);
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [choices, setChoices] = useState([]);
	const { user } = useAppContext();
	const [showBranchOption, setShowBranchOption] =
		useState(isHiddenBranchOption);

	const getChoices = async () => {
		try {
			const response = await axios.get(
				`/question/choice/${question.questionId}`
			);
			setChoices(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const handleAddQuestionChoice = async () => {
		try {
			const questionChoice = new QuestionChoice(
				question.questionId,
				choices.length || 0,
				' '
			);
			await axios.post('question/choice/create', questionChoice);
			getChoices();
		} catch (err) {
			console.log(err);
		}
	};

	const handleQuestionChoiceTextChange = async (index, text) => {
		const updatedChoices = [...choices];
		updatedChoices[index].choiceText = text;
		setChoices(updatedChoices);
		debounce(async () => {
			try {
				const questionChoice = new QuestionChoice(
					updatedChoices[index].questionId,
					updatedChoices[index].choiceIndex,
					updatedChoices[index].choiceText
				);
				questionChoice.questionChoiceId =
					updatedChoices[index].questionChoiceId;
				await axios.post('question/choice/create', questionChoice);
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const handleDeleteOption = async (index) => {
		const updatedChoices = [...choices];
		const questionChoiceId = updatedChoices[index].questionChoiceId;
		try {
			await axios.delete(`question/choice/${questionChoiceId}`);
			getChoices();
		} catch (err) {
			console.log(err);
		}
	};

	const handleOptionChange = async (e) => {
		const newValue = e.target.value;
		setSelectedOption(newValue);
		try {
			await axios.post('question/response/save', {
				...question,
				numberResponse: newValue,
				dinerId: question.dinerId,
				surveyResponseId: question.surveyResponseId,
				questionResponseId: question.questionReponseId,
			});

			if (question.questionBranches && question.questionBranches.length > 0) {
				const matchingBranch = question.questionBranches.find(
					(branch) => branch.numberResponse === newValue
				);

				question.questionBranches.forEach((branch) => {
					if (branch !== matchingBranch) {
						handleConditionalQuestionBranches(
							branch.conditionalQuestionId,
							true
						);
					}
				});

				if (matchingBranch) {
					handleVisibilityChange(matchingBranch.conditionalQuestionId, false);
				}
			}
			getSurvey();
		} catch (err) {
			console.error(err);
		}
	};

	const handleConditionalQuestionBranches = async (
		conditionalQuestionId,
		isHidden
	) => {
		const conditionalQuestion = findQuestionById(conditionalQuestionId);
		await axios.post(
			`question/response/clear/${conditionalQuestion.questionId}/${user.userId}`
		);
		if (!conditionalQuestion) return;

		handleVisibilityChange(conditionalQuestionId, isHidden);

		if (
			conditionalQuestion.questionBranches &&
			conditionalQuestion.questionBranches.length > 0
		) {
			for (const branch of conditionalQuestion.questionBranches) {
				await handleConditionalQuestionBranches(
					branch.conditionalQuestionId,
					isHidden
				);
			}
		}
	};

	const findQuestionById = (questionId) => {
		for (const question of questions) {
			if (question.questionId === questionId) {
				return question;
			}
		}
		return null;
	};

	const handleVisibilityChange = (questionId, newVisibility) => {
		setIsHiddenQuestion(questionId, newVisibility);
	};

	useEffect(() => {
		if (!user.admin) {
			if (question.questionResponses?.[0]?.numberResponse === null) {
				setSelectedOption('');
			} else {
				setSelectedOption(question.questionResponses[0].numberResponse);
			}
		}
		setIsCreate(user.admin);
		setChoices(question.questionChoices);
		setShowBranchOption(isHiddenBranchOption);
	}, [question, user, isHiddenBranchOption, question.questionResponses]);

	return (
		<Stack alignItems={'left'} spacing={2}>
			{isCreate ? (
				<>
					<TextField
						label='Enter new question title'
						value={questionText}
						onChange={handleQuestionTextChange}
						fullWidth
					/>
					{choices.map((choice) => (
						<Stack
							key={choice.choiceIndex}
							direction='row'
							alignItems='center'
							spacing={1}>
							<Radio disabled />
							<TextField
								label={`Option ${choice.choiceIndex + 1}`}
								value={choice.choiceText}
								onChange={(e) =>
									handleQuestionChoiceTextChange(
										choice.choiceIndex,
										e.target.value
									)
								}
								fullWidth
							/>
							{!showBranchOption && (
								<QuestionBranch
									question={question}
									questions={questions}
									numberResponse={choice.choiceIndex}
									getSurvey={getSurvey}
								/>
							)}
							<IconButton
								onClick={() => handleDeleteOption(choice.choiceIndex)}>
								<DeleteIcon />
							</IconButton>
						</Stack>
					))}
					<Button variant='outlined' onClick={handleAddQuestionChoice}>
						Add Option
					</Button>
				</>
			) : (
				<FormControl component='fieldset'>
					<RadioGroup
						name='multiple-choice'
						value={selectedOption}
						onChange={handleOptionChange}>
						{choices.map((choice) => (
							<FormControlLabel
								className='multiple-choice'
								sx={{
									fontSize: '.875rem',
									color:
										selectedOption === choice.choiceIndex
											? '#000000'
											: '#686868',
								}}
								disableTypography
								key={choice.questionChoiceId}
								value={choice.choiceIndex}
								control={
									<Radio
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 28,
											},
										}}
									/>
								}
								label={choice.choiceText}
							/>
						))}
					</RadioGroup>
				</FormControl>
			)}
		</Stack>
	);
};

export default MultipleChoice;
